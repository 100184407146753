import './mobilemenu.scss';
import '../../../vendor/mmenu-9.3.0/mmenu.min.css';

// import WhBase from '@mod-system/js/compat/base';
import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';

import $ from 'jquery';
require('../../../vendor/mmenu-9.3.0/mmenu.min.js');

dompack.onDomReady(() => {
  let $body = $('body');

  $(
  '<div id="navButton">' +
    '<a href="#" class="toggle"></a>' +
  '</div>'
  )
  .appendTo($body);

  $('#navButton a.toggle').click(function(evt) {
    evt.preventDefault();
  });

  let settings = {
    "extensions": ["position-top"],
    "navbar": {
      title: " "
    },
  };

  if (whintegration.config.site.hassearchpage === true)
    settings.searchfield = { add: true, search: false, placeholder: 'Zoeken', clear: true }

  let $menu = new Mmenu( "#my-menu", settings );

  if (whintegration.config.site.hassearchpage === true) {
    $("#my-menu .mm-search input").keyup(function(event) {
      if (event.keyCode === 13) {
        location.href = whintegration.config.site.searchpagelink + '?words=' + $(this).val();
      }
    });
  }

  let $icon = $("#navButton");
  const api = $menu.API;

  $('#navButton').click(function() {
    api.open();
  });
});
