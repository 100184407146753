import './slideshow.scss';

import * as dompack from "dompack";

import $ from 'jquery';
import Swiper from 'swiper';

dompack.onDomReady(() => {
  if ($('.slideshow').length === 0)
    return;

  $('.slideshow').each(function () {
    let $container = $(this).find('.swiper-container').first();

    let numSlides = $container.find('.swiper-slide').length;

    if (numSlides < 2)
      $container.find('.swiper-pagination, .swiper-button-prev, .swiper-button-next').remove();

    const swiper = new Swiper($container, {
      direction: 'horizontal',
      loop: numSlides > 1,
      speed: 500,
      autoplay: {
        delay: 4000,
        pauseOnMouseEnter: true,
      },
      grabCursor: numSlides > 1,
      pagination: '.swiper-pagination',
      paginationClickable: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      spaceBetween: 0,
      allowSwipeToPrev: numSlides > 1,
      allowSwipeToNext: numSlides > 1,
    });

    // if ($(this).attr('data-pauseonhover')) {
    //   $container.hover(function() {
    //     swiper.autoplay.stop();
    //   }, function() {
    //     swiper.autoplay.start();
    //   });
    // }
  });
});
