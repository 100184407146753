
(function($) {

  skel.breakpoints({
    xlarge: '(max-width: 1680px)',
    large: '(max-width: 1280px)',
    medium: '(max-width: 980px)',
    small: '(max-width: 736px)',
    xsmall: '(max-width: 480px)'
  });

  $(function() {

    var $window = $(window),
      $body = $('body'),
      $html = $('html');

    // Disable animations/transitions until the page has loaded.
//      $body.addClass('is-loading');
//
//      $window.on('load', function() {
//        window.setTimeout(function() {
//          $body.removeClass('is-loading');
//        }, 0);
//      });

    // Fix: Placeholder polyfill.
      $('form').placeholder();

    // Prioritize "important" elements on medium.
      skel.on('+medium -medium', function() {
        $.prioritize(
          '.important\\28 medium\\29',
          skel.breakpoint('medium').active
        );
      });

    // Dropdowns.
      $('#nav > ul').dropotron({
        alignment: 'center',
        hideDelay: 350
      });

      // Navigation Panel.
        $(
          '<div id="navPanel">' +
            '<nav>' +
              $('#nav').navList() +
            '</nav>' +
          '</div>'
        )
          .appendTo($body)
          .panel({
            delay: 500,
            hideOnClick: true,
            resetScroll: true,
            resetForms: true,
            side: 'top',
            target: $html,
            visibleClass: 'navPanel-visible'
          });

      // Fix: Remove transitions on WP<10 (poor/buggy performance).
        if (skel.vars.os == 'wp' && skel.vars.osVersion < 10)
          $('#header, #navButton, #navPanel, #page-wrapper')
            .css('transition', 'none');

    // IE8 fixes.
      if (skel.vars.IEVersion < 9) {

        // Odd/even on wrapper.split.
          $('.wrapper.split .secondary > section').each(function(i) {
            $(this).addClass((i + 1) % 2 == 0 ? 'even' : 'odd');
          });

      }

  });

})(jQuery);
