// general JS (always add)
import '@mod-mijnsiteonline/nodejs/mso';

// main CSS
import './bedum.scss';
import 'font-awesome/css/font-awesome.min.css';
// import 'swiper/dist/css/swiper.min.css';

// pages
import './pages/agenda/agenda';
import './pages/intranet/intranet';
import './pages/news/news';
import './pages/organizations/organizations';
import './pages/team/team';

// components
import './mobilemenu/mobilemenu';
import './slideshow/slideshow';
import './widgets/widgets';

// template specific JS files
import $ from 'jquery';
window.jQuery = $;
require('./web/js/jquery.dropotron.min.js');
window.skel = require('./web/js/skel.min.js');
require('./web/js/util.js');
require('./web/js/main.js');
require('swiper');

import * as DomPack from 'dompack';

DomPack.onDomReady(() => {
  if ($('#sidebar').length) {
    let $sections = $('#sidebar > section');
    $sections.each(function(idx, el) {
      if (idx < $sections.length - 1) {
        $(this).after('<hr />');
      }
    });
  }
});

let touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
if (!touchsupport){ // browser doesn't support touch
  document.documentElement.className += ' non-touch';
}
